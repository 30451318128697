import axios from 'axios'

//const nodeServerUrl = 'http://localhost:7777'

export function getNiceData() {
  //var url = baseUrl + str
  //var url = nodeServerUrl
  let wl = window.location,
  // url = `${wl.protocol}//${wl.hostname}:7777`,
  url = `${wl.protocol}//${wl.hostname}/nice`,
            //인증 후 callback URL
            returnUrl = url+'/nice/decrypt/data',
            //callback 후 WAS에서 최종적으로 redirect 시킬 URL(결과 화면)
            redirectUrl = `${wl.protocol}//${wl.host}/mintit/agency/account`

  axios({
    method: 'get',
    url: url+'/nice/encrypt/data',
    params : { returnUrl, redirectUrl},
    withCredentials: true
  }).then(res => {  
    sendPost('https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb', {'m' : 'checkplusService', 'EncodeData' : res.data})
  }).catch( err => {
    console.error(err)
  })
}

function sendPost(url, params) {
  var form = document.createElement('form')
  form.setAttribute('charset', 'UTF-8')
  form.setAttribute('method', 'post')
  form.setAttribute('target', '')
  form.setAttribute('action', url)

  for (var key in params) {
    var hiddenField = document.createElement('input')
    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('name', key)
    hiddenField.setAttribute('value', params[key])
    form.appendChild(hiddenField)
  }

  document.body.appendChild(form)
  form.submit()
}
