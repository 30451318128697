<template>
  <vp-base-layout page-title="본인 인증">
    <div class="l-wrapper">
      <div
        class="l-contents"
        style="margin: 50px">
        <p>본인인증 후 가입을 진행해주세요.</p>
        <p>인증하신 전화번호로 카카오톡/문자가 전송됩니다.</p>
        <ion-button expand="block" @click="handlePage(order_tel_no)">휴대폰 본인 인증하기</ion-button>
      </div>
      <div class="card-button">
        <vp-button
          expand="full"
          @click="handlePage(order_tel_no)"
          :types="[{'fixed': fixed}]">
          휴대폰 인증하기
        </vp-button>
      </div>
    </div>
  </vp-base-layout>
</template>
 
<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import { ref } from 'vue'
import {
  IonInput, 
  IonItem, 
  IonLabel, 
  useIonRouter,
  alertController
} from '@ionic/vue'
import VpPageTitle from '@/components//original/layouts/VpPageTitle.vue'
import { getNiceData } from '@/utils/niceAuth'

const ionRouter = useIonRouter()
//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const fixed = ref(true)
const order_tel_no = ref('')

const handleCheck = async() => {
    const alert = await alertController.create({
      message: '전화번호를 입력해주세요.',
      buttons: ['확인']
    })
    await alert.present()
}

const handlePage = (item) => {
  // if(item == '') {
  //   handleCheck()
  //   return
  // }

  getNiceData()
  //ionRouter.push(`/mintit/agency/account/${item}`)

}
//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------

</script>
